/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";

/*Prime Component*/
import { Toast } from "primereact/toast";
import { TabView, TabPanel } from "primereact/tabview";
import { Chart } from "primereact/chart";

/*Component*/
import ContentLoading from "../loading/ContentLoading";

/*Plugins*/
import { useTranslation } from "react-i18next";

//Component Pages
import LogList from "../log/List";
import LogCustomerStatusList from "../log/CustomerStatusList";
import UserCustomerNote from "../note/UserNote";
import CustomerNoteAll from "../note/allCustomerNote";

/*Yetkilendirme ve Rol*/
import {
  tumMusteriNotlar,
  tumHareketGecmisi,
  tumMusteriSorumluKisiGecmisi,
} from "../../constants/string";
import { JwtHelper } from "../../plugins/jwtHelper";
import { MyDailyReport } from "../../service/dashboard";

const Dashboard = () => {
  const { t } = useTranslation();
  const authorityList = JwtHelper.getAuthority(); //Yetkileri getirir
  const [isLoading] = useState(false);
  const toast = useRef(null);

  const chartLabel = [];
  const chartCount = [];
  //Kendi Raporlarım İçin Chart
  const [chartData, setChartData] = useState();
  useEffect(() => {
    MyDailyReport(JwtHelper.getUserId()).then((res) => {
      console.log(res);
      res.map((item) => {
        chartLabel.push(item.label);
        chartCount.push(item.count);
      });
      setChartData({
        datasets: [
          {
            data: chartCount,
            backgroundColor: [
              "#42A5F5",
              "#EC407A",
              "#AB47BC",
              "#7E57C2",
              "#66BB6A",
              "#FFCA28",
              "#26A69A",
              "#e8dcb0",
              "#d470a2",
              "#787ef4",
              "#f90bab",
              "#dfa700",
              "#cc3c63",
              "#82c9b6",
              "#ccbbbb",
              "#ff8b94",
              "#cc4668",
            ],
            label: "Raporlarım",
          },
        ],
        labels: chartLabel,
      });
    });
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="p-fluid">
          <Toast ref={toast} />
          <TabView>
            <TabPanel header={t("anasayfa.kendiRaporlarim")}>
              <div className="grid">
                <div className="col-6">
                  <h5>{t("anasayfa.kendiRaporlarimBaslik")}</h5>
                  <Chart
                    type="doughnut"
                    data={chartData}
                    style={{ position: "relative" }}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel header={t("anasayfa.kendiActigimNotlar")}>
              <UserCustomerNote />
            </TabPanel>
            {authorityList.includes(tumMusteriNotlar) && (
              <TabPanel header={t("anasayfa.tumMusteriNotlari")}>
                <CustomerNoteAll />
              </TabPanel>
            )}
            {authorityList.includes(tumHareketGecmisi) && (
              <TabPanel header={t("anasayfa.tumHareketGecmisi")}>
                <LogList />
              </TabPanel>
            )}
            {authorityList.includes(tumMusteriSorumluKisiGecmisi) && (
              <TabPanel header={t("anasayfa.tumMusteriSorumluKisiGecmisi")}>
                <LogCustomerStatusList />
              </TabPanel>
            )}
            {/* {authorityList.includes(yeniGelistirmeBilgileri) && (
              <TabPanel header="Yeni Geliştirme Bilgilendirmesi">
                <div className="">
                  <div className="flex align-items-center justify-content-between mb-4">
                    <h5>Son Güncelleme Notları</h5>
                  </div>

                  <span className="block text-600 font-medium mb-3">
                    19 Ağustos 2022
                  </span>
                  <ul className="p-0 mx-0 mt-0 mb-4 list-none">
                    <li className="flex align-items-center py-2 border-bottom-1 surface-border">
                      <div className="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
                        <i className="pi pi-volume-down text-xl text-blue-500" />
                      </div>
                      <span className="text-900 line-height-3">
                        <b>
                          Bazı hata düzeltmeleri ile yeni geliştirmeler şu
                          şekildedir.
                        </b>
                        <br></br>
                        1. Müşteri listesinde oluşturma tarihi gösterilecek
                        <br></br>
                        2. müşteri listesinde toplu atama sonrasında toast
                        gösterilecek ve liste yenilenecek<br></br>
                        3. Client lead durumundan müşteri olarak ayarlama
                        yapamasın sadece admin yetkili olanlar bunu yapabilsin.
                        <br></br>
                        4. Tüm müşteri listesini excell olarak indirme<br></br>
                        5. Recapte entegrasyonu (Güvenlik iyileştirmesi)
                        <br></br>
                        6. Personel listesinde global arama özelliği<br></br>
                        7. Takım listesinde global arama özelliği<br></br>
                        8. Lead listesinde global arama özelliği<br></br>
                        9. Rol listesinde global arama özelliği<br></br>
                        10. Sol menü iconlarının ilgili textlerle uygun halel
                        getirilmesi<br></br>
                        11. crm’deki numaralar / yanlarına lead durumu ve en son
                        lead durumu ne zaman güncellenmiş bilgilerini içeren
                        tablo<br></br>
                        12. whatsapp entegrasyonu<br></br>
                      </span>
                    </li>
                  </ul>
                </div>
              </TabPanel>
            )} */}
          </TabView>
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Dashboard, comparisonFn);
