/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import { MultiSelect } from "primereact/multiselect";

import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";

// plugins
import moment from "moment";
import { useTranslation } from "react-i18next";

// Prime Component
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { confirmPopup } from "primereact/confirmpopup";
import { Toast } from "primereact/toast";
import { TabView, TabPanel } from "primereact/tabview";
import { Card } from "primereact/card";
import { Checkbox } from "primereact/checkbox";
import { Calendar } from "primereact/calendar";

/*Component*/
import ContentLoading from "../loading/ContentLoading";
import NotesAdd from "./NotesAdd";
import ReminderAdd from "./RemirderAdd";
import Whatsapp from "../../module/whatsapp/Whatsapp";
import WhatsappCustomerList from "../../module/whatsapp/WhatsappCustomerList";

/*Service*/
import {
  CustomersGetList,
  CustomersPut,
  CustomerAssignmentGet,
  CustomersUpdateAll,
  CustomersGetFindAllList,
  CustomersDetele,
} from "../../service/customer";
import { NotesCustomerGet } from "../../service/note";
import { PersonnelGetList } from "../../service/personnel";
import { LeadGetList } from "../../service/lead";

/*Yetkilendirme ve Rol*/
import {
  musteriListesi,
  musteriListesiSorumluPersonelGecmisi,
  musteriTelefonNumarasi,
  musteriDetay,
  momentDateFormat,
  musteriListesiTopluGuncelleme,
  musteriListesiAtamaGuncelleme,
  musteriListesiLeadGuncelleme,
  musteriSilme,
} from "../../constants/string";

import { JwtHelper } from "../../plugins/jwtHelper";
import { CallHistoriesPost } from "../../service/callHistoryService";
import { Messages } from "primereact/messages";

const List = () => {
  const { t } = useTranslation();
  const columns = [
    { field: "name", header: t("musteriListesi.adiSoyadi") , filter: "true" },
    {
      field: "phone",
      header: t("musteriListesi.telefon"),
      template: "customerPhoneBodyTemplate",
      filter: "true",
    },
    {
      field: "leadStateId",
      header: t("musteriListesi.lead"),
      template: "customerStatusBodyTemplate",
      filter: "true",
    },
    {
      field: "id",
      header: t("musteriListesi.musteriId"),
      filter: "true",
    },
    {
      field: "metaId",
      header: t("musteriListesi.metaId"),
      filter: "true",
      template: "metaIdBodyTemplate",
    },
    {
      field: "source",
      header: t("musteriListesi.kaynak"),
      filter: "true",
    },
    {
      field: "source2",
      header: t("musteriListesi.kaynak2"),
      filter: "true",
    },
    {
      field: "source3",
      header: t("musteriListesi.kaynak3"),
      filter: "true",
    },
    {
      field: "personnelId",
      header: t("musteriListesi.sorumlu"),
      filter: "true",
      template: "personnelBodyTemplate",
    },
    {
      field: "createdDate",
      header: t("musteriListesi.olusturma"),
      filter: "false",
      template: "momentCreatedDateBodyTemplate",
    },
    {
      field: "updatedDate",
      header: t("musteriListesi.guncelleme"),
      filter: "false",
      template: "momentUpdateDateBodyTemplate",
    },
  ];
  const [visibleColumns, setVisibleColumns] = useState([
    { field: "name", header: t("musteriListesi.adiSoyadi"), filter: "true" },
    {
      field: "phone",
      header: t("musteriListesi.telefon"),
      template: "customerPhoneBodyTemplate",
      filter: "true",
    },
    {
      field: "leadStateId",
      header: t("musteriListesi.lead"),
      template: "customerStatusBodyTemplate",
      filter: "true",
    },
    {
      field: "id",
      header: t("musteriListesi.musteriId"),
      filter: "true",
    },
    {
      field: "metaId",
      header: t("musteriListesi.metaId"),
      filter: "true",
      template: "metaIdBodyTemplate",
    },
    {
      field: "source",
      header: t("musteriListesi.kaynak"),
      filter: "true",
    },
    {
      field: "source2",
      header: t("musteriListesi.kaynak2"),
      filter: "true",
    },
    {
      field: "source3",
      header: t("musteriListesi.kaynak3"),
      filter: "true",
    },
    {
      field: "personnelId",
      header: t("musteriListesi.sorumlu"),
      filter: "true",
      template: "personnelBodyTemplate",
    },
    {
      field: "createdDate",
      header: t("musteriListesi.olusturma"),
      filter: "false",
      template: "momentCreatedDateBodyTemplate",
    },
    {
      field: "updatedDate",
      header: t("musteriListesi.guncelleme"),
      filter: "false",
      template: "momentUpdateDateBodyTemplate",
    },
  ]);
  const toast = useRef(null);
  const [fastNoteAddDisplay, setFastNoteAddDisplay] = useState(false); //Müşteri Hızlı Not Ekleme Dialog'u
  const [fastRemirderAddDisplay, setFastRemirderAddDisplay] = useState(false); //Müşteri Hızlı Hatırlatıcı Ekleme Dialog'u
  const [
    customerPersonnelStatusHistoryLog,
    setCustomerPersonnelStatusHistoryLog,
  ] = useState(false); //Müşteri Sorumlu Kişi Geçmişi Log
  const [customerLeadStateDisplay, setCustomerLeadStateDisplay] =
    useState(false); //Müşteri lead durumu modal
  const [customerWhatsappDisplay, setCustomerWhatsappDisplay] = useState(false); //Müşteri Whatsapp modal
  const [customerData, setCustomerData] = useState([]); //Alt komponentler için gerekli müşteri bilgileri
  const [customerLeadStateData, setCustomerLeadStateData] = useState(); //Alt komponentler için gerekli müşteri bilgileri
  const [leadStates, setLeadStates] = useState([]); //Filtre alanı için gerekli lead durumları

  const [customerNoteData, setcustomerNoteData] = useState([]); //Müşteriye Ait Olan Müşteri Notları Listesi
  const [customerNoteChecked, setCustomerNoteChecked] = useState(false); //Hızlı Lead Durumu Değiştirme Modalında Müşteri Yorumu Eklemek İçin Checkbox

  const [isLoading, setIsLoading] = useState(true);
  const [customerNotesListModalLoading, setCustomerNotesListModalLoading] =
    useState(true);
  const [allCustomerUpdateLoading, setAllCustomerUpdateLoading] =
    useState(false);
  const authorityList = JwtHelper.getAuthority(); //Yetkileri getirir
  const jwtEncode = JwtHelper.getJwtObj(); //Jwt decode edilmiş halini getirir
  const [personnelList, setPersonnelList] = useState({}); //Personel Listesi
  const [leadList, setleadListList] = useState({}); //Lead Listesi
  const [allCustomerDataSelected, setAllCustomerDataSelected] = useState(); //Tüm müşteri listesini seçen checkbox
  const history = useHistory();

  console.log("jwtEncode", jwtEncode);

  const fastNoteAddDisplayDataHandle = (status) => {
    //Müşteri Hızlı Not Ekleme Handdle
    setFastNoteAddDisplay(status);
    toast.current.show({
      severity: "success",
      summary: "Başarılı",
      detail: "Başarılı Şekilde Not Eklendi",
      life: 3000,
    });
  };

  const fastRemirderAddDisplayDataHandle = (status) => {
    //Müşteri Hızlı Hatırlatıcı Ekleme Handdle
    setFastRemirderAddDisplay(status);
    toast.current.show({
      severity: "success",
      summary: "Başarılı",
      detail: "Başarılı Şekilde Hatırlatıcı Eklendi",
      life: 3000,
    });
  };

  //Dropdown Search Code Start
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="country-item">
        <div>{option.name}</div>
      </div>
    );
  };

  //Listelemeye düzenleme ve silme butonunu eklediğimiz kısım
  const actionBodyTemplate = (rowData) => {
    if (authorityList.includes(musteriDetay)) {
      return (
        <React.Fragment>
          <Link
            className="p-button-rounded p-button-success"
            target="_blank"
            to={`/musteri/${rowData.id}`}
          >
            <Button
              icon="pi pi-eye"
              className="p-button-rounded p-button-success mr-2"
            />
          </Link>
          <Button
            icon="pi pi-book"
            className="p-button-rounded p-button-info mr-2 "
            tooltip="Müşteri Notu"
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
            }}
            onClick={() => {
              setCustomerData(rowData);
              setFastNoteAddDisplay(true);
              customerNotesListClick(rowData);
            }}
          />
          <Button
            icon="pi pi-bell"
            className="p-button-rounded p-button-danger mr-2 "
            tooltip="Hatırlatıcı"
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
            }}
            onClick={() => {
              setCustomerData(rowData);
              setFastRemirderAddDisplay(true);
            }}
          />
          {authorityList.includes(musteriListesiSorumluPersonelGecmisi) && (
            <Button
              icon="pi pi-user"
              className="p-button-rounded p-button-help mr-2 "
              tooltip="Sorumlu Personel Geçmişi"
              tooltipOptions={{
                position: "bottom",
                mouseTrack: true,
                mouseTrackTop: 15,
              }}
              onClick={() => {
                customerHistoryPersonnelStatus(rowData);
                setCustomerPersonnelStatusHistoryLog(true);
              }}
            />
          )}

          {rowData.metaAccountList !== null &&
            (JwtHelper.isMt4Enable() || JwtHelper.isMt5Enable()) &&
            (JwtHelper.getUserRole() === "admin" ||
              JwtHelper.getUserRole() === "retention") && (
              <Button
                icon="pi pi-chart-line"
                className="p-button-rounded p-button-primary mr-2 "
                tooltip="Meta Bilgileri"
                tooltipOptions={{
                  position: "bottom",
                  mouseTrack: true,
                  mouseTrackTop: 15,
                }}
                onClick={() => {
                  history.push("/mt4-detail/" + rowData.id);
                }}
              />
            )}
          {authorityList.includes(musteriSilme) && (
            <Button
              icon="pi pi-trash"
              className="p-button-rounded p-button-danger  mr-2"
              tooltip="Müşteri Sil"
              tooltipOptions={{
                position: "bottom",
                mouseTrack: true,
                mouseTrackTop: 15,
              }}
              onClick={() => {
                confirm2(rowData.id);
              }}
            />
          )}
        </React.Fragment>
      );
    }
    return "";
  };

  const [lastPage, setLastPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customers, setCustomers] = useState(null); //Müşteri Listesi

  const [searchAdValue, setSearchAdValue] = useState("");
  const [searchAdValue2, setSearchAdValue2] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [searchSoyadValue, setSearchSoyadValue] = useState("");
  const [searchSoyadValue2, setSearchSoyadValue2] = useState("");
  const [searchTelefonValue, setSearchTelefonValue] = useState("");
  const [searchTelefonValue2, setSearchTelefonValue2] = useState("");
  const [searchKaynakValue, setSearchKaynakValue] = useState("");
  const [searchKaynakValue2, setSearchKaynakValue2] = useState("");
  const [searchLeadValue, setSearchLeadValue] = useState("");
  const [searchLeadValue2, setSearchLeadValue2] = useState("");
  const [searchPersonnelValue, setSearchPersonnelValue] = useState("");
  const [searchPersonnelValue2, setSearchPersonnelValue2] = useState("");
  const [metaIdPersonnelValue, setmetaIdPersonnelValuePersonnelValue] =
    useState("");
  const [
    metaIdPersonnelValuePersonnelValue2,
    setmetaIdPersonnelValuePersonnelValue2,
  ] = useState("");
  const [leadStateChangeButNoteFound, setLeadStateChangeButNoteFound] =
    useState(false);

  const [lazyParams, setLazyParams] = useState({
    //Lazy load - sayfalama ve filtreleme kısımları
    first: 1,
    rows: localStorage.getItem("customerRows")
      ? parseInt(localStorage.getItem("customerRows"))
      : 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: "", matchMode: "contains" },
      surname: { value: "", matchMode: "contains" },
      email: { value: "", matchMode: "contains" },
      leadStateId: { value: 0, matchMode: "equals" },
      isCalled: { value: "", matchMode: "equals" },
      phone: { value: "", matchMode: "contains" },
      source: { value: "", matchMode: "contains" },
      source2: { value: "", matchMode: "contains" },
      source3: { value: "", matchMode: "contains" },
      personnelId: { value: 0, matchMode: "equals" },
      metaId: { value: "", matchMode: "contains" },
    },
  });
  const [selectedProducts7, setSelectedProducts7] = useState(null); //Checkbox İlle Seçilen Elemenları tutar

  let loadLazyTimeout = null;

  useEffect(() => {
    PersonnelGetList().then((res) => {
      //Personel Listesini Getirelim
      setPersonnelList(res);
    });
    LeadGetList().then((res) => {
      //Lead Listesini Getirelim
      // eslint-disable-next-line eqeqeq
      if (JwtHelper.getUser().role == "client") {
        const index = res.findIndex(function (o) {
          return o.name === "Müşteri";
        });
        if (index !== -1) res.splice(index, 1);
      }
      setleadListList(res);
      setLeadStates(res);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadLazyData();
  }, [lazyParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadLazyData = (sortUpdateDate) => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //imitate delay of a backend call
    loadLazyTimeout = setTimeout(() => {
      var nameValue = "";
      if (lazyParams.filters.name === undefined) {
        //Eğer yetkide görünmüyor ise hatayı fixleyelim
      } else {
        if (lazyParams.filters.name.value === null) {
          nameValue = "";
        } else {
          nameValue = lazyParams.filters.name.value;
          // eslint-disable-next-line eqeqeq
          if (searchAdValue != "") {
            setSearchAdValue2(nameValue);
            if (searchAdValue2 == nameValue) {
            } else {
              lazyParams.page = 0;
            }
          }
          if (searchAdValue == "" && searchAdValue2 != "") {
            lazyParams.page = 0;
          }
        }
      }
      var surnameValue = "";
      if (lazyParams.filters.surname === undefined) {
        //Eğer yetkide görünmüyor ise hatayı fixleyelim
      } else {
        if (lazyParams.filters.surname.value === null) {
          surnameValue = "";
        } else {
          surnameValue = lazyParams.filters.surname.value;
          if (searchSoyadValue != "") {
            setSearchSoyadValue2(surnameValue);
            if (searchSoyadValue2 == surnameValue) {
            } else {
              lazyParams.page = 0;
            }
          }
          if (searchSoyadValue == "" && searchSoyadValue2 != "") {
            lazyParams.page = 0;
          }
        }
      }
      var phoneValue = "";
      if (lazyParams.filters.phone === undefined) {
        //Eğer yetkide görünmüyor ise hatayı fixleyelim
      } else {
        if (lazyParams.filters.phone.value === null) {
          phoneValue = "";
        } else {
          phoneValue = lazyParams.filters.phone.value;
          if (searchTelefonValue != "") {
            setSearchTelefonValue2(phoneValue);
            if (searchTelefonValue2 == phoneValue) {
            } else {
              lazyParams.page = 0;
            }
          }
          if (searchTelefonValue == "" && searchTelefonValue2 != "") {
            lazyParams.page = 0;
          }
        }
      }

      var leadStateIdValue = "";
      if (lazyParams.filters.leadStateId === undefined) {
        lazyParams.filters.leadStateId = "";
      } else {
        if (lazyParams.filters.leadStateId.value === null) {
          leadStateIdValue = "";
        } else {
          if (lazyParams.filters.leadStateId.value === undefined) {
            //eğer böyle yapmaz isek backende undefined veri atıyor.
            leadStateIdValue = "";
          } else {
            leadStateIdValue = lazyParams.filters.leadStateId.value;
            if (searchLeadValue != "") {
              setSearchLeadValue2(leadStateIdValue);
              if (searchLeadValue2 == leadStateIdValue) {
              } else {
                lazyParams.page = 0;
              }
            }
            if (searchLeadValue == "" && searchLeadValue2 != "") {
              lazyParams.page = 0;
            }
          }
        }
      }
      var sourceValue = "";
      if (lazyParams.filters.source === undefined) {
        //Eğer yetkide görünmüyor ise hatayı fixleyelim
      } else {
        if (lazyParams.filters.source.value === null) {
          sourceValue = "";
        } else {
          sourceValue = lazyParams.filters.source.value;
          if (searchKaynakValue != "") {
            setSearchKaynakValue2(sourceValue);
            if (searchKaynakValue2 == sourceValue) {
            } else {
              lazyParams.page = 0;
            }
          }
          if (searchKaynakValue == "" && searchKaynakValue2 != "") {
            lazyParams.page = 0;
          }
        }
      }

      var personnelIdValue = "";
      if (lazyParams.filters.personnelId === undefined) {
        lazyParams.filters.personnelId = "";
      } else {
        if (lazyParams.filters.personnelId.value === null) {
          personnelIdValue = "";
        } else {
          if (lazyParams.filters.personnelId.value === undefined) {
            //eğer böyle yapmaz isek backende undefined veri atıyor.
            personnelIdValue = "";
          } else {
            personnelIdValue = lazyParams.filters.personnelId.value;
            if (searchPersonnelValue != "") {
              setSearchPersonnelValue2(personnelIdValue);
              if (searchPersonnelValue2 == personnelIdValue) {
              } else {
                lazyParams.page = 0;
              }
            }
            if (searchPersonnelValue == "" && searchPersonnelValue2 != "") {
              lazyParams.page = 0;
            }
          }
        }
      }

      var metaIdValue = "";
      if (lazyParams.filters.metaId === undefined) {
        lazyParams.filters.metaId = "";
      } else {
        if (lazyParams.filters.metaId.value === null) {
          metaIdValue = "";
        } else {
          if (lazyParams.filters.metaId.value === undefined) {
            //eğer böyle yapmaz isek backende undefined veri atıyor.
            metaIdValue = "";
          } else {
            metaIdValue = lazyParams.filters.metaId.value;
            if (metaIdPersonnelValue != "") {
              setmetaIdPersonnelValuePersonnelValue2(metaIdValue);
              if (metaIdPersonnelValuePersonnelValue2 == metaIdValue) {
              } else {
                lazyParams.page = 0;
              }
            }
            if (
              metaIdPersonnelValue == "" &&
              metaIdPersonnelValuePersonnelValue2 != ""
            ) {
              lazyParams.page = 0;
            }
          }
        }
      }

      if (lazyParams.filters.isCalled?.value === undefined) {
        lazyParams.filters.isCalled.value = "";
      }
      if (lazyParams.filters.isCalled?.value === null) {
        lazyParams.filters.isCalled.value = "";
      }
      console.log("lazyParams", lazyParams);
      CustomersGetList(
        lazyParams.page,
        nameValue,
        surnameValue,
        phoneValue,
        leadStateIdValue,
        sourceValue,
        personnelIdValue,
        lazyParams.rows,
        metaIdValue,
        dateData,
        lazyParams.filters.isCalled.value,
        sortUpdateDate,
        leadStateChangeButNoteFound
      ).then((response) => {
        console.log("data", response);
        setTotalRecords(response.totalElements);
        setCustomers(response);
        setLoading(false);
        setIsLoading(false);
        setLastPage(lazyParams.page);
      });
    }, Math.random() * 1000 + 1000);
  };

  const filterCreatedRemoveValue = () => {
    setDateData({
      ...dateData,
      createdDateStart: null,
      createdDateEnd: null,
    });
  };
  const filterUpdatesRemoveValue = () => {
    setDateData({
      ...dateData,
      updatedDateStart: null,
      updatedDateEnd: null,
    });
  };

  const onPage = (event) => {
    event.filters = lazyParams.filters;
    lazyParams.rows = event.rows;
    localStorage.setItem("customerRows", event.rows);
    setLazyParams(event);
  };

  const onFilter = (event) => {
    setSearchAdValue(event.filters.name.value);
    // setSearchSoyadValue(event.filters.surname.value)
    setSearchTelefonValue(event.filters?.phone?.value);
    setSearchKaynakValue(event.filters?.source?.value);
    setSearchLeadValue(event.filters?.leadStateId?.value);
    setSearchPersonnelValue(event.filters?.personnelId?.value);
    setmetaIdPersonnelValuePersonnelValue(event.filters?.metaId?.value);
    event.page = lazyParams.page;
    event["first"] = 0;
    setLazyParams(event);
  };

  const momentCreatedDateBodyTemplate = (rowData) => {
    //Listeleme Tarih Formatı
    return moment(rowData.createdDate).format(momentDateFormat);
  };
  const momentUpdateDateBodyTemplate = (rowData) => {
    //Listeleme Güncelleme Tarih Formatı
    if (
      rowData.updatedDate === null ||
      rowData.updatedDate === "" ||
      rowData.updatedDate === undefined
    ) {
      return "";
    } else {
      return moment(rowData.updatedDate).format(momentDateFormat);
    }
  };

  const callCenter = (data, rowData) => {
    callHistoryPost(rowData.id);
    window.open(`sip:${data.phoneNumber}`, "_blank");
  };

  const whatsappTop = (data) => {
    // setCustomerWhatsappDisplay(true)
    // setCustomerData(data);

    const phone = data.phoneNumber.replace(/ /g, "");
    var phone2 = data.phoneNumber;
    if (phone[0] === "0") {
      var phone2 = phone.substring(1);
    }
    if (phone[0] + phone[1] === "90") {
      var phone2 = phone.substring(2);
    }
    if (phone[0] + phone[1] + phone[2] === "+90") {
      var phone2 = phone.substring(3);
    }
    if (jwtEncode.group?.id === 4) {
      window.open(`https://wa.me/${phone2}`, "_blank");
    } else {
      window.open(`https://wa.me/+90${phone2}`, "_blank");
    }
  };

  const customerPhoneBodyTemplate = (rowData) => {
    //Listeleme Telefon gösterimi
    return rowData.phoneNumberList.map((item, i) => {
      return (
        <span key={i} style={{ display: "block", marginBottom: 15 }}>
          <Button
            icon="pi pi-whatsapp"
            className="p-button-success mr-2"
            style={{ width: 30, heigth: 10 }}
            tooltip="Whatsapp Mesajı Gönder"
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
            }}
            onClick={() => whatsappTop(item)}
          />
          <Button
            icon="pi pi-phone"
            className="p-button-info mr-2"
            style={{ width: 30, heigth: 10 }}
            tooltip="Müşteriyi Ara"
            tooltipOptions={{
              position: "bottom",
              mouseTrack: true,
              mouseTrackTop: 15,
            }}
            onClick={() => callCenter(item, rowData)}
          />
          {authorityList.includes(musteriTelefonNumarasi) && (
            <span style={{ lineHeight: "40px" }}>{item.phoneNumber}</span>
          )}
        </span>
      );
    });
  };
  const metaIdBodyTemplate = (rowData) => {
    if (rowData?.metaAccountList) {
      return rowData?.metaAccountList.map((item, i) => {
        return (
          <div style={{ display: "block" }} key={i}>
            {item?.login}
          </div>
        );
      });
    }
  };

  const customerStatusBodyTemplate = (rowData) => {
    //Listeleme lead durumu düzenleme ve gösterimi
    var statusText = "";
    if (rowData.leadState.id === 1) {
      //Yeni Kayıt
      statusText = "green";
    } else if (rowData.leadState.id === 2) {
      //Cevapsız
      statusText = "yellow";
    } else if (rowData.leadState.id === 3) {
      //İlgisiz
      statusText = "grey";
    } else if (rowData.leadState.id === 4) {
      //Müsait Değil
      statusText = "lila";
    } else if (rowData.leadState.id === 5) {
      //Müşteri
      statusText = "lila";
    } else if (rowData.leadState.id === 6) {
      //Büyük Potansiyel
      statusText = "blue";
    } else if (rowData.leadState.id === 7) {
      //Küçük Potansiyel
      statusText = "lightBlue";
    } else if (rowData.leadState.id === 8) {
      //Uzak Takip
      statusText = "darkGreen";
    } else if (rowData.leadState.id === 9) {
      //Aranması Gereken
      statusText = "black";
    } else {
      statusText = "default";
    }
    return (
      <span style={{ display: "block" }}>
        <Button
          icon="pi pi-pencil"
          className="p-button-success mr-2"
          style={{ width: 30, heigth: 10 }}
          tooltip="Lead Durumunu Düzenle"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          onClick={() => {
            setCustomerData(rowData);
            setCustomerLeadStateDisplay(true);
          }}
        />
        <span
          style={{ lineHeight: "40px" }}
          className={`product-badge customer-badge status-${statusText}`}
        >
          {rowData.leadState.name}
        </span>
      </span>
    );
  };

  const personnelBodyTemplate = (rowData) => {
    //Listeleme lead durumu düzenleme ve gösterimi
    return (
      <span style={{ display: "block", marginBottom: 15 }}>
        <span style={{ lineHeight: "40px" }}>{rowData.user.name}</span>
      </span>
    );
  };
  const [collectiveData, setCollectiveData] = useState({
    //Toplu İşlem Datalarını Tutar
    leadState: null,
    user: null,
  });
  const allCustomerOnChangeList = (status) => {
    //Bu kısım Tüm Müşteri Listesini Çekmek İçin Yapılmıştır.
    setAllCustomerUpdateLoading(true);
    var nameValue = "";
    if (lazyParams.filters.name === undefined) {
      //Eğer yetkide görünmüyor ise hatayı fixleyelim
    } else {
      if (lazyParams.filters.name.value === null) {
        nameValue = "";
      } else {
        nameValue = lazyParams.filters.name.value;
        if (searchAdValue != "") {
          setSearchAdValue2(nameValue);
          if (searchAdValue2 == nameValue) {
          } else {
            lazyParams.page = 0;
          }
        }
        if (searchAdValue == "" && searchAdValue2 != "") {
          lazyParams.page = 0;
        }
      }
    }
    var surnameValue = "";
    if (lazyParams.filters.surname === undefined) {
      //Eğer yetkide görünmüyor ise hatayı fixleyelim
    } else {
      if (lazyParams.filters.surname.value === null) {
        surnameValue = "";
      } else {
        surnameValue = lazyParams.filters.surname.value;
        if (searchSoyadValue != "") {
          setSearchSoyadValue2(surnameValue);
          if (searchSoyadValue2 == surnameValue) {
          } else {
            lazyParams.page = 0;
          }
        }
        if (searchSoyadValue == "" && searchSoyadValue2 != "") {
          lazyParams.page = 0;
        }
      }
    }
    var phoneValue = "";
    if (lazyParams.filters.phone === undefined) {
      //Eğer yetkide görünmüyor ise hatayı fixleyelim
    } else {
      if (lazyParams.filters.phone.value === null) {
        phoneValue = "";
      } else {
        phoneValue = lazyParams.filters.phone.value;
        if (searchTelefonValue != "") {
          setSearchTelefonValue2(phoneValue);
          if (searchTelefonValue2 == phoneValue) {
          } else {
            lazyParams.page = 0;
          }
        }
        if (searchTelefonValue == "" && searchTelefonValue2 != "") {
          lazyParams.page = 0;
        }
      }
    }

    var leadStateIdValue = "";
    if (lazyParams.filters.leadStateId === undefined) {
      lazyParams.filters.leadStateId = "";
    } else {
      if (lazyParams.filters.leadStateId.value === null) {
        leadStateIdValue = "";
      } else {
        if (lazyParams.filters.leadStateId.value === undefined) {
          //eğer böyle yapmaz isek backende undefined veri atıyor.
          leadStateIdValue = "";
        } else {
          leadStateIdValue = lazyParams.filters.leadStateId.value;
          if (searchLeadValue != "") {
            setSearchLeadValue2(leadStateIdValue);
            if (searchLeadValue2 == leadStateIdValue) {
            } else {
              lazyParams.page = 0;
            }
          }
          if (searchLeadValue == "" && searchLeadValue2 != "") {
            lazyParams.page = 0;
          }
        }
      }
    }
    var sourceValue = "";
    if (lazyParams.filters.source === undefined) {
      //Eğer yetkide görünmüyor ise hatayı fixleyelim
    } else {
      if (lazyParams.filters.source.value === null) {
        sourceValue = "";
      } else {
        sourceValue = lazyParams.filters.source.value;
        if (searchKaynakValue != "") {
          setSearchKaynakValue2(sourceValue);
          if (searchKaynakValue2 == sourceValue) {
          } else {
            lazyParams.page = 0;
          }
        }
        if (searchKaynakValue == "" && searchKaynakValue2 != "") {
          lazyParams.page = 0;
        }
      }
    }

    var personnelIdValue = "";
    if (lazyParams.filters.personnelId === undefined) {
      lazyParams.filters.personnelId = "";
    } else {
      if (lazyParams.filters.personnelId.value === null) {
        personnelIdValue = "";
      } else {
        if (lazyParams.filters.personnelId.value === undefined) {
          //eğer böyle yapmaz isek backende undefined veri atıyor.
          personnelIdValue = "";
        } else {
          personnelIdValue = lazyParams.filters.personnelId.value;
          if (searchPersonnelValue != "") {
            setSearchPersonnelValue2(personnelIdValue);
            if (searchPersonnelValue2 == personnelIdValue) {
            } else {
              lazyParams.page = 0;
            }
          }
          if (searchPersonnelValue == "" && searchPersonnelValue2 != "") {
            lazyParams.page = 0;
          }
        }
      }
    }

    var metaIdValue = "";
    if (lazyParams.filters.metaId === undefined) {
      lazyParams.filters.metaId = "";
    } else {
      if (lazyParams.filters.metaId.value === null) {
        metaIdValue = "";
      } else {
        if (lazyParams.filters.metaId.value === undefined) {
          //eğer böyle yapmaz isek backende undefined veri atıyor.
          metaIdValue = "";
        } else {
          metaIdValue = lazyParams.filters.metaId.value;
          if (metaIdPersonnelValue != "") {
            setmetaIdPersonnelValuePersonnelValue2(metaIdValue);
            if (metaIdPersonnelValuePersonnelValue2 == metaIdValue) {
            } else {
              lazyParams.page = 0;
            }
          }
          if (
            metaIdPersonnelValue == "" &&
            metaIdPersonnelValuePersonnelValue2 != ""
          ) {
            lazyParams.page = 0;
          }
        }
      }
    }
    if (status) {
      CustomersGetFindAllList(
        nameValue,
        surnameValue,
        phoneValue,
        leadStateIdValue,
        sourceValue,
        personnelIdValue,
        metaIdValue
      )
        .then((res) => {
          //Müşteri Listesi toplu güncelleme (lead ve Sorumlu Personel)
          toast.current.show({
            severity: "success",
            summary: "Başarılı",
            detail: "Başarılı Şekilde Tüm Müşteri Listesi Çekildi.",
            life: 3000,
          });
          setSelectedProducts7(res);
          setAllCustomerUpdateLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);

          toast.current.show({
            severity: "error",
            summary: "Hata",
            detail: `${err.response.data}`,
            life: 3000,
          });
        });
    } else {
      setSelectedProducts7([]);
      setAllCustomerUpdateLoading(false);
    }
  };
  const bulkTransfer = (row) => {
    //Burası Toplu Update İşlemleri için yazılmıştır.
    setIsLoading(true);
    if (collectiveData.leadState === null && collectiveData.user === null) {
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Lütfen Sorumlu Personel Veya Lead Durumu Seçiniz.",
        life: 3000,
      });
      setIsLoading(false);
    } else {
      selectedProducts7.map((item, i) => {
        if (collectiveData.leadState == null) {
        } else {
          item.leadState = collectiveData.leadState;
        }
        if (collectiveData.user == null) {
        } else {
          item.user = collectiveData.user;
        }
      });
      CustomersUpdateAll(selectedProducts7)
        .then((res) => {
          //Müşteri Listesi toplu güncelleme (lead ve Sorumlu Personel)
          toast.current.show({
            severity: "success",
            summary: "Başarılı",
            detail: "Başarılı Şekilde Müşteriler Atandı",
            life: 3000,
          });
          setSelectedProducts7([]);
          loadLazyData();
        })
        .catch((err) => {
          setIsLoading(false);

          toast.current.show({
            severity: "error",
            summary: "Hata",
            detail: `${err.response.data}`,
            life: 3000,
          });
        });
    }
  };

  const [
    customerHistoryPersonnelStatusData,
    setCustomerHistoryPersonnelStatusData,
  ] = useState([]); //Müşterinin sorumlu personel geçmişi
  const customerHistoryPersonnelStatus = (rowData) => {
    CustomerAssignmentGet(rowData.id)
      .then((res) => {
        //Personel Listesini Getirelim
        setCustomerHistoryPersonnelStatusData(res);
      })
      .catch((err) => {
        setIsLoading(false);

        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: `${err.response.data}`,
          life: 3000,
        });
      });
  };

  const btnLeadStateEdit = () => {
    customerData.phoneNumberList.forEach((value) => {
      //telefon numaralarında ki müşteriID boş giderse backend'de telefon numarası boşa çıkıyor.
      if (value.customerId == null) {
        value.customerId = customerData.id;
      }
    });
    customerData.leadState = customerLeadStateData;
    CustomersPut(customerData)
      .then((res) => {
        //Müşteri Listesi lead durumu güncelleme
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Başarılı Şekilde Lead Durumu Güncellendi",
          life: 3000,
        });
        setCustomerLeadStateDisplay(false);
        if (customerNoteChecked) {
          setFastNoteAddDisplay(true);
          setCustomerNoteChecked(false);
        }
        loadLazyData();
      })
      .catch((err) => {
        setIsLoading(false);

        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: `${err.response.data}`,
          life: 3000,
        });
      });
  };

  const statusItemTemplate = (option) => {
    //Lead durumu filter kısmı için renkli dropdown textleri
    var statusText = "";
    if (option.id === 1) {
      //Yeni Kayıt
      statusText = "green";
    } else if (option.id === 2) {
      //Cevapsız
      statusText = "yellow";
    } else if (option.id === 3) {
      //İlgisiz
      statusText = "grey";
    } else if (option.id === 4) {
      //Müsait Değil
      statusText = "lila";
    } else if (option.id === 5) {
      //Müşteri
      statusText = "lila";
    } else if (option.id === 6) {
      //Büyük Potansiyel
      statusText = "blue";
    } else if (option.id === 7) {
      //Küçük Potansiyel
      statusText = "lightBlue";
    } else if (option.id === 8) {
      //Uzak Takip
      statusText = "darkGreen";
    } else if (option.id === 9) {
      //Aranması Gereken
      statusText = "black";
    } else {
      statusText = "default";
    }
    return (
      <span className={`customer-badge status-${statusText}`}>
        {option.name}
      </span>
    );
  };

  const leadStateFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={leadStates}
        optionValue="id"
        optionLabel="name"
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={statusItemTemplate}
        placeholder={t("global.placeholderSelect")}
        className="p-column-filter"
        filter
        showClear
      />
    );
  };

  const isCalledList = [
    { id: "true", name: "Arama Yapılanları Göster" },
    { id: "false", name: "Arama Yapılmayanları Göster" },
  ];
  const isCalledFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={isCalledList}
        optionValue="id"
        optionLabel="name"
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={statusItemTemplate}
        placeholder={t("global.placeholderSelect")}
        className="p-column-filter"
        filter
        showClear
      />
    );
  };

  const PersonnelFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={personnelList}
        optionValue="id"
        optionLabel="name"
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder={t("global.placeholderSelect")}
        className="p-column-filter"
        filter
        showClear
      />
    );
  };

  const customerNotesListClick = (rowData) => {
    NotesCustomerGet(rowData.id).then((res) => {
      //Müşteri Notlarını Getirelim
      setcustomerNoteData(res);
      setCustomerNotesListModalLoading(false);
    });
  };

  //Silme İşlemleri Başlar
  const accept = (id) => {
    //Silme işlemi evet diyince
    CustomersDetele(id)
      .then((res) => {
        loadLazyData();
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: `${err.response.data}`,
          life: 3000,
        });
      });
  };

  const reject = () => {
    //Silme işlemi hayır diyince
  };

  //Silme Butonu Emin Misiniz Sorusu?
  const confirm2 = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Silme İşlemini Yapmak İstediğinize Emin misiniz?",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      accept: () => accept(event),
      reject,
    });
  };

  const [dateData, setDateData] = useState({
    createdDateStart: null,
    createdDateEnd: null,
    updatedDateStart: null,
    updatedDateEnd: null,
  });
  const filterBtn = () => {
    loadLazyData();
  };
  const filterBtnUpdateDate = () => {
    loadLazyData();
  };

  const callHistoryPost = (id) => {
    CallHistoriesPost(id)
      .then((res) => {
        //console.log("başarılı")
      })
      .catch((err) => {
        setIsLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: `${err.response.data}`,
          life: 3000,
        });
      });
  };

  const msgs = useRef(null);

  const [updateDateSortText, setUpdateDateSortText] = useState("asc");
  const onSort = () => {
    if (updateDateSortText == "asc") {
      setUpdateDateSortText("desc");
    } else {
      setUpdateDateSortText("asc");
    }
    loadLazyData(updateDateSortText);
  };

  useEffect(() => {
    loadLazyData();
  }, [leadStateChangeButNoteFound]);
  const leadStateButNotFoundCheckBoxOnChange = (e) => {
    console.log("change : ", e.checked);
    setLeadStateChangeButNoteFound(e.checked);
  };

  const header = (
    <div className="flex justify-content-center mb-4">
      <label className="d-block w-full">{t("musteriListesi.kolonlariOzellestir")}</label>
      <MultiSelect
        value={visibleColumns}
        options={columns}
        optionLabel="header"
        onChange={(e) => onColumnToggle(e.value)}
        className="w-full"
        display="chip"
        maxSelectedLabels={4}
      />
    </div>
  );

  const onColumnToggle = (event) => {
    let selectedColumns = event;
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );
    localStorage.setItem(
      "customerListColumns",
      JSON.stringify(orderedSelectedColumns)
    );
    setVisibleColumns(orderedSelectedColumns);
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("customerListColumns"))) {
      const columsLocal = JSON.parse(
        localStorage.getItem("customerListColumns")
      );
      setVisibleColumns(columsLocal);
    }
  }, []);

  return (
    <>
      <Toast ref={toast} />
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="grid p-fluid">
          {authorityList.includes(musteriListesi) ? (
            <div className="col-12">
              <div className="card">
                <h5>{t("musteriListesi.baslik")}</h5>
                <p>{t("musteriListesi.aciklama")}</p>
                {authorityList.includes(musteriListesiTopluGuncelleme) && (
                  <div className="grid">
                    {allCustomerUpdateLoading ? (
                      <ContentLoading />
                    ) : (
                      <div className="grid">
                        {authorityList.includes(
                          musteriListesiAtamaGuncelleme
                        ) && (
                          <div className="col-12 md:col-4">
                            <div className="field">
                              <label htmlFor="personnelEMail">
                                {t("musteriListesi.sorumlu")}
                              </label>
                              <Dropdown
                                value={collectiveData.user}
                                options={personnelList}
                                onChange={(e) =>
                                  setCollectiveData({
                                    ...collectiveData,
                                    user: e.value,
                                  })
                                }
                                optionLabel="name"
                                filter
                                showClear
                                filterBy="name"
                                placeholder={t("global.placeholderSelect")}
                                valueTemplate={selectedCountryTemplate}
                                itemTemplate={countryOptionTemplate}
                              />
                            </div>
                          </div>
                        )}
                        {authorityList.includes(
                          musteriListesiLeadGuncelleme
                        ) && (
                          <div className="col-12 md:col-4">
                            <div className="field">
                              <label htmlFor="personnelEMail">
                                {t("musteriListesi.lead")}
                              </label>
                              <Dropdown
                                value={collectiveData.leadState}
                                options={leadList}
                                onChange={(e) =>
                                  setCollectiveData({
                                    ...collectiveData,
                                    leadState: e.value,
                                  })
                                }
                                optionLabel="name"
                                filter
                                showClear
                                filterBy="name"
                                placeholder={t("global.placeholderSelect")}
                                valueTemplate={selectedCountryTemplate}
                                itemTemplate={countryOptionTemplate}
                              />
                            </div>
                          </div>
                        )}
                        <div className="col-12 md:col-4">
                          <div className="field" style={{ marginTop: 5 }}>
                            <Button
                              label={t("global.btnUpdateText")}
                              onClick={bulkTransfer}
                              className="p-button-success mt-4"
                            />
                          </div>
                        </div>
                        <div className="col-12 md:col-12">
                          <div className="field-checkbox">
                            <Checkbox
                              inputId="binary"
                              checked={allCustomerDataSelected}
                              onChange={(e) => {
                                setAllCustomerDataSelected(e.checked);
                                allCustomerOnChangeList(e.checked);
                              }}
                            />
                            <label htmlFor="binary">
                              {t("musteriListesi.tumMusterileriSec")}
                            </label>
                          </div>
                          <div className="field-checkbox">
                            <Checkbox
                              inputId="selectedLeadState"
                              checked={leadStateChangeButNoteFound}
                              value={leadStateChangeButNoteFound}
                              onChange={leadStateButNotFoundCheckBoxOnChange}
                            />
                            <label htmlFor="selectedLeadState">
                              {t("musteriListesi.leadDurumudegismis")}
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div className="grid">
                  <div className="col-12 md:col-4">
                    <div className="field">
                      <label htmlFor="personnelEMail">
                      {t("musteriListesi.olusturmaTarihiBaslangic")}
                      </label>
                      <Calendar
                        showButtonBar
                        showIcon
                        placeholder="Lütfen Seçim Yapınız"
                        id="basic"
                        value={dateData.createdDateStart}
                        onChange={(e) =>
                          setDateData({
                            ...dateData,
                            createdDateStart: e.value,
                          })
                        }
                        dateFormat="dd-mm-yy"
                      />
                    </div>
                  </div>
                  <div className="col-12 md:col-4">
                    <div className="field">
                      <label htmlFor="personnelEMail">
                      {t("musteriListesi.olusturmaTarihiBitis")}
                      </label>

                      <Calendar
                        showButtonBar
                        showIcon
                        placeholder="Lütfen Seçim Yapınız"
                        id="basic"
                        value={dateData.createdDateEnd}
                        onChange={(e) =>
                          setDateData({ ...dateData, createdDateEnd: e.value })
                        }
                        dateFormat="dd-mm-yy"
                      />
                    </div>
                  </div>
                  <div className="col-12 md:col-2">
                    <Button
                      label={t("musteriListesi.filtrele")}
                      icon="pi pi-check"
                      className="p-button-success"
                      style={{ marginTop: "1.7rem" }}
                      onClick={(e) => {
                        filterBtn();
                      }}
                    />
                  </div>
                  <div className="col-12 md:col-2">
                    <Button
                      label={t("musteriListesi.filtreyiTemizle")}
                      icon="pi pi-check"
                      className="p-button-info"
                      style={{ marginTop: "1.7rem" }}
                      onClick={(e) => {
                        filterCreatedRemoveValue();
                      }}
                    />
                  </div>
                </div>
                <div className="grid">
                  <div className="col-12 md:col-4">
                    <div className="field">
                      <label htmlFor="personnelEMail">
                      {t("musteriListesi.GuncellemeBaslangic")}
                      </label>
                      <Calendar
                        showButtonBar
                        showIcon
                        placeholder="Lütfen Seçim Yapınız"
                        id="basic"
                        value={dateData.updatedDateStart}
                        onChange={(e) =>
                          setDateData({
                            ...dateData,
                            updatedDateStart: e.value,
                          })
                        }
                        dateFormat="dd-mm-yy"
                      />
                    </div>
                  </div>
                  <div className="col-12 md:col-4">
                    <div className="field">
                      <label htmlFor="personnelEMail">
                      {t("musteriListesi.GuncellemeBitis")}
                      </label>

                      <Calendar
                        showButtonBar
                        showIcon
                        placeholder="Lütfen Seçim Yapınız"
                        id="basic"
                        value={dateData.updatedDateEnd}
                        onChange={(e) =>
                          setDateData({ ...dateData, updatedDateEnd: e.value })
                        }
                        dateFormat="dd-mm-yy"
                      />
                    </div>
                  </div>
                  <div className="col-12 md:col-2">
                    <Button
                      label={t("musteriListesi.filtrele")}
                      icon="pi pi-check"
                      className="p-button-success"
                      style={{ marginTop: "1.7rem" }}
                      onClick={(e) => {
                        filterBtnUpdateDate();
                      }}
                    />
                  </div>
                  <div className="col-12 md:col-2">
                    <Button
                     label={t("musteriListesi.filtreyiTemizle")}
                      icon="pi pi-check"
                      className="p-button-info"
                      style={{ marginTop: "1.7rem" }}
                      onClick={(e) => {
                        filterUpdatesRemoveValue();
                      }}
                    />
                  </div>
                </div>

                <DataTable
                  header={header}
                  value={customers.content}
                  lazy
                  filterDisplay="row"
                  responsiveLayout="scroll"
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  rows={lazyParams.rows}
                  first={lazyParams.first}
                  totalRecords={totalRecords}
                  onPage={onPage}
                  onFilter={onFilter}
                  loading={loading}
                  selection={selectedProducts7}
                  onSelectionChange={(e) => setSelectedProducts7(e.value)}
                  sortOrder={lazyParams.sortOrder}
                  onSort={onSort}
                  rowsPerPageOptions={[
                    10, 20, 30, 40, 50, 100, 150, 200, 250, 300,
                  ]}
                >
                  <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "3em" }}
                  ></Column>
                  <Column
                    body={actionBodyTemplate}
                    header="İşlem"
                    exportable={false}
                    style={{ minWidth: "18rem" }}
                    filter
                    filterElement={isCalledFilterTemplate}
                    field="isCalled"
                  ></Column>
                  {visibleColumns?.map((col, i) => {
                    var template = null;
                    var templateElement = null;
                    var filterNone = null;
                    if (col.template == "customerPhoneBodyTemplate") {
                      template = customerPhoneBodyTemplate;
                    }
                    if (col.template == "customerStatusBodyTemplate") {
                      template = customerStatusBodyTemplate;
                      templateElement = leadStateFilterTemplate;
                    }
                    if (col.template == "personnelBodyTemplate") {
                      template = personnelBodyTemplate;
                      templateElement = PersonnelFilterTemplate;
                    }
                    if (col.template == "momentCreatedDateBodyTemplate") {
                      template = momentCreatedDateBodyTemplate;
                      filterNone = true;
                    }
                    if (col.template == "momentUpdateDateBodyTemplate") {
                      template = momentUpdateDateBodyTemplate;
                      filterNone = true;
                    }
                    if (col.template == "metaIdBodyTemplate") {
                      template = metaIdBodyTemplate;
                    }
                    if (filterNone) {
                      return (
                        <Column
                          style={{ minWidth: "15rem" }}
                          key={i}
                          field={col.field}
                          header={col.header}
                          body={template}
                          sortable
                        />
                      );
                    }
                    return (
                      <Column
                        style={{ minWidth: "15rem" }}
                        key={i}
                        field={col.field}
                        header={col.header}
                        body={template}
                        filterElement={templateElement}
                        filter={col.filter}
                        filterPlaceholder={t(
                          "global.tablePlaceholderSearchText"
                        )}
                      />
                    );
                  })}
                </DataTable>
                <span className="mr-3 mt-3" style={{ display: "inline-block" }}>
                  Toplam Kayıt Sayısı: {customers.totalElements}
                </span>
                <span className="mr-3 mt-3" style={{ display: "inline-block" }}>
                  Toplam Sayfa Sayısı: {customers.totalPages}
                </span>

                {/* Hızlı Müşteri Notu Ekleme */}
                <Dialog
                  header="Müşteri Notu"
                  visible={fastNoteAddDisplay}
                  style={{ width: "50vw" }}
                  onHide={() => setFastNoteAddDisplay(false)}
                >
                  <TabView>
                    <TabPanel header="Müşteri Notu Ekle">
                      <NotesAdd
                        customerData={customerData}
                        fastNoteAddDisplayDataHandle={
                          fastNoteAddDisplayDataHandle
                        }
                      />
                    </TabPanel>
                    <TabPanel header="Müşteri Notu Listesi">
                      <div className="grid">
                        {customerNotesListModalLoading ? (
                          <ContentLoading />
                        ) : customerNoteData.length > 0 ? (
                          customerNoteData.map((item, i) => {
                            return (
                              <div className="col-12 md:col-4" key={i}>
                                <Card
                                  title={item.title}
                                  subTitle={"Oluşturan:" + item.createdUser}
                                  style={{ width: "100%" }}
                                >
                                  <p
                                    className="m-0"
                                    style={{ lineHeight: "1.5" }}
                                  >
                                    {item.content}
                                  </p>
                                  <div className="p-card-subtitle mt-4">
                                    Oluşturma Tarihi;<br></br>
                                    <b>
                                      {moment(item.createdDate).format(
                                        momentDateFormat
                                      )}
                                    </b>
                                  </div>
                                </Card>
                              </div>
                            );
                          })
                        ) : (
                          <h5 className="col-12">
                            Müşteriye Ait Not Bulunamadı
                          </h5>
                        )}
                      </div>
                    </TabPanel>
                  </TabView>
                </Dialog>

                {/* Hızlı Müşteri Hatırlatıcı Ekleme */}
                <Dialog
                  header="Müşteri Hatırlatıcı Ekle"
                  visible={fastRemirderAddDisplay}
                  style={{ width: "50vw" }}
                  onHide={() => setFastRemirderAddDisplay(false)}
                >
                  <ReminderAdd
                    customerData={customerData}
                    fastRemirderAddDisplayDataHandle={
                      fastRemirderAddDisplayDataHandle
                    }
                  />
                </Dialog>

                {/* Müşteri Listesi Sorumlu Kişi Geçmişi Log */}
                <Dialog
                  header="Sorumlu Personel Geçmişi"
                  visible={customerPersonnelStatusHistoryLog}
                  style={{ width: "50vw" }}
                  onHide={() => setCustomerPersonnelStatusHistoryLog(false)}
                >
                  <DataTable
                    value={customerHistoryPersonnelStatusData}
                    responsiveLayout="scroll"
                    emptyMessage="Herhangi Bir Data Bulunuamadı."
                  >
                    <Column
                      field="customer.name"
                      header="Müşteri Adı"
                      filterPlaceholder={t("global.tablePlaceholderSearchText")}
                    />
                    <Column
                      field="customer.surname"
                      header="Müşteri Soyadı"
                      filterPlaceholder={t("global.tablePlaceholderSearchText")}
                    />
                    <Column
                      field="user.name"
                      header="Sorumlu Kişi"
                      filterPlaceholder={t("global.tablePlaceholderSearchText")}
                    />
                    <Column
                      field="createdDate"
                      header="Değişiklik Tarihi"
                      body={momentCreatedDateBodyTemplate}
                      filterPlaceholder={t("global.tablePlaceholderSearchText")}
                    />
                  </DataTable>
                </Dialog>

                {/* Müşteri Listesi Lead Durumu Değiştirme */}
                <Dialog
                  header="Müşteri Lead Değiştir"
                  visible={customerLeadStateDisplay}
                  style={{ width: "50vw" }}
                  onHide={() => setCustomerLeadStateDisplay(false)}
                >
                  <div className="field">
                    <label
                      style={{ display: "block" }}
                      htmlFor="personnelLeadState"
                    >
                      Lead Durumu
                    </label>
                    <Dropdown
                      value={customerLeadStateData}
                      options={leadList}
                      onChange={(e) => {
                        setCustomerLeadStateData(e.value);
                        if (
                          e.value !== undefined &&
                          JwtHelper.isConnectPanel()
                        ) {
                          if (e.value.name === "Müşteri") {
                            msgs.current.show([
                              {
                                severity: "warn",
                                detail:
                                  "Lead durumu Müşteri olarak değiştirildiğinde Panel'de hesap açılacaktır. Fakat e-posta adresi doğru girilmediğinde hesap panelde açılamayacaktır. Lütfen e-posta adresini kontrol ediniz.",
                                sticky: true,
                              },
                            ]);
                          } else {
                            msgs.current.clear();
                          }
                        } else {
                          msgs.current.clear();
                        }
                      }}
                      optionLabel="name"
                      filter
                      showClear
                      filterBy="name"
                      placeholder={t("global.placeholderSelect")}
                      valueTemplate={selectedCountryTemplate}
                      itemTemplate={countryOptionTemplate}
                    />
                    <Messages ref={msgs} />
                  </div>

                  <div className="field-checkbox">
                    <Checkbox
                      inputId="binary11111"
                      checked={customerNoteChecked}
                      onChange={(e) => setCustomerNoteChecked(e.checked)}
                    />
                    <label htmlFor="binary11111">
                      Müşteri Notu Eklemek İstiyorum
                    </label>
                  </div>
                  <div className="field" style={{ marginTop: 5 }}>
                    <Button
                      label="Güncelle"
                      onClick={btnLeadStateEdit}
                      className="p-button-success mt-4"
                    />
                  </div>
                </Dialog>

                {/* Müşteri Listesi Lead Durumu Değiştirmef */}
                <Dialog
                  header="Joy Fx Whatsapp"
                  visible={customerWhatsappDisplay}
                  style={{ width: "95vw", padding: "0px" }}
                  onHide={() => setCustomerWhatsappDisplay(false)}
                >
                  <div className="grid">
                    <div className="col-12 md:col-3">
                      <WhatsappCustomerList></WhatsappCustomerList>
                    </div>
                    <div className="col-12 md:col-9">
                      <Whatsapp customerData={customerData} />
                    </div>
                  </div>
                </Dialog>
              </div>
            </div>
          ) : (
            <div className="col-12">
              <div className="card">
                <h5>Müşteri Listesi</h5>
                <p>Müşteri listesinde yer alanlar aşağıda listelenmektedir.</p>
                <h2>Lütfen Yetkili Bir Kullanıcı İle Giriş Yapınız</h2>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(List, comparisonFn);
